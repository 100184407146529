import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import Image from 'Components/Image';
import Button from 'Components/Button';
import s from './BillboardCampaign.module.scss';

const BillboardCampaign = ({title, text, image, button, alignRight}) => {
    const classes = classNames(
        s['BillboardCampaign'],
        {[s['BillboardCampaign--Right']]: alignRight},
        {[s['BillboardCampaign--Left']]: !alignRight},
    );
    return (
        <div className={classes}>
            <div className={s['BillboardCampaign__Image']}>
                <Image {...image} />
            </div>

            <div className={s['BillboardCampaign__Wrap']}>
                <div className={s['BillboardCampaign__Content']}>
                    <h2 className={s['BillboardCampaign__Title']}>{title}</h2>

                    {!_.isEmpty(text) &&
                        <p className={s['BillboardCampaign__Text']}>{text}</p>
                    }

                    {!_.isEmpty(button) &&
                        <Button
                            {...button}
                            className={s['BillboardCampaign__Button']}
                            modifier="PrimaryLink"
                        />
                    }
                </div>
            </div>
        </div>
    );
};

BillboardCampaign.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string,
    image: PropTypes.object.isRequired,
    button: PropTypes.object,
    alignRight: PropTypes.bool.isRequired,
};

BillboardCampaign.defaultProps = {
    title: '',
    text: '',
    image: null,
    button: null,
    alignRight: false,
};

export default BillboardCampaign;
